import Layout from './components/Layout';
import Home from './components/Home/Home';
import NotFound from './components/NotFound/NotFound';
import ProjectPage from './components/ProjectPage/ProjectPage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import aos from 'aos';
import 'aos/dist/aos.css';
import { useRoutes, BrowserRouter } from 'react-router-dom';

aos.init({
  duration: 500
});

const AppRoutes = () => {
  let routes = useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/project-page/:project',
      element: <ProjectPage />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ])

  return routes;
}

function App() {

  return (
    <Layout>
      <BrowserRouter>
        <Header />
        <AppRoutes />
      </BrowserRouter>
      <Footer />
    </Layout>
  );
}

export default App;
