import styled from 'styled-components'

const WorkItemStyled = styled.div`
    
    margin: 2em 0 0;
    position: relative;
    overflow: hidden;
    color: var(--white);
    width: 100%;
    min-height: 160px;

    .work-item-content {
        position: relative;
        z-index: 2;
        width: 320px;
        min-height: 140px;
        margin-top: 2.5em;
        padding: 0.5em 1em;
        border-bottom: 1px solid var(--green);

        @media only screen and (max-width: 460px) {
            width: 300px;
        }

        .work-item {
            position: relative;
            z-index: 2;
        }

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: none;
            top: 0;
            left: 0;
            z-index: 0;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }

        &:before {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 100%;
            position: absolute;
            background: var(--green);
            border: 2px solid var(--green);
            top: 43%;
            z-index: 1;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }

        .triangle {
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 15px 20px 15px;
            border-color: transparent transparent var(--green) transparent;
            transform: rotate(90deg);
            position: absolute;
        }

        h4 {
            text-transform: uppercase;
            color: var(--green);
            margin: 0.3em 0;
            font-weight: 600;
            letter-spacing: 2px;
            font-size: 20px;
        }

        h5 {
            margin: 1.3em 0;
            line-height: 20px;
            font-size: 16px;
            font-weight: 400;
        }

        .work-dates-history {
            font-size: 15px;
            color: var(--light-gray);

            strong {
                color: var(--green);
                font-weight: 500;
            }
        }
    }

`

const WorkItem = (props) => {

    return (
        <WorkItemStyled className="work-item-wrapper" data-aos="zoom-out-up">
            <div className="work-item-content">
                <div className="work-item">
                    <h4>
                        {props.title}
                    </h4>

                    <h5>
                        {props.field_work_description}
                    </h5>

                    <div className="work-dates-history">
                        <strong>From:</strong> {props.field_work_from_date} <strong>To:</strong> {props.field_work_to_date}
                    </div>

                </div>

                <div className="triangle"></div>
            </div>        
        </WorkItemStyled>
    )
}

export default WorkItem
