import styled from "styled-components";
import { NavLink } from "react-router-dom";

const PortfolioItemStyled = styled.div``;

const PortfolioItem = (props) => {

    const IMG_BASE_URL = "https://drupal-api.in-deev.com";

    return (
        <PortfolioItemStyled className="portfolio-item" style={{ backgroundImage: `url('${IMG_BASE_URL}${props.field_thumbnail}')` }}>

        <div className="porfolio-item-title">{props.title}</div>
            <NavLink
                className="view-project"
                to={`/project-page/${props.title.split(" ").join("-").toLowerCase()}`}
            >
                <span>View Project</span>
            </NavLink>
        </PortfolioItemStyled>
        );
    }

export default PortfolioItem;
