
const BASE_API = 'https://drupal-api.in-deev.com';

async function fetchWrapper(url, options) {

    const response = await fetch(url, options)

    // console.log(`Fetch url is ${url}`);

    if (!response.ok) {
        return {
            data: null,
            isError: true
        }
    }

    const data =  await response.json();
    
    return {
        data,
        isError: false
    }

}

export async function getData(api_end_point) {
    return fetchWrapper(`${BASE_API}/${api_end_point}?_format=json`);
}