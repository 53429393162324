import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import React, { useState } from 'react';

const NavbarStyled = styled.div`

`

const Navbar = () => {

    const [isActive, setIsActive] = useState(false);

    function handdleNavClick(e) {
        const element = document.getElementById(e.currentTarget.dataset.id);
        element.scrollIntoView({behavior: "smooth", block: "start"});
        setIsActive(false);
    }

    const toggleClass = () => {
        setIsActive(!isActive);
    };
    
    return (
        <NavbarStyled className='navbar-wrapper'>
            <div className="navbar-left-section">
                <div className="navbar-left-content">
                <NavLink 
                    to="/" 
                    className={`navbar-home-cta`}
                    data-id="intro"
                    onClick={handdleNavClick}
                >
                DLC
                </NavLink>
                </div>
            </div>
            <div className="navbar-right-section">
                <nav className={isActive ? 'active' : ''}>
                    <ul>
                        <li>
                            <NavLink
                                to="/#about-me"
                                className="nav-bar-link" 
                                data-id="about-me"
                                onClick={handdleNavClick}
                            >
                                About Me
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/#skills-and-tools"
                                data-id="skills-and-tools"
                                className="nav-bar-link" 
                                onClick={handdleNavClick}
                            >
                                Skills & Tools
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/#work-experience"
                                data-id="work-experience"
                                className="nav-bar-link" 
                                onClick={handdleNavClick}
                            >
                                Work Experience
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/#portfolio"
                                className="nav-bar-link" 
                                data-id="portfolio"
                                onClick={handdleNavClick}
                            >
                                Portfolio
                            </NavLink>
                        </li>
                    </ul>

                </nav>
                <div id="mobile-menu-btn" className={`${isActive ? 'active' : ''}`} onClick={toggleClass}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </NavbarStyled>
    )
}

export default Navbar;

