import Intro from '../Intro/Intro';
import { useState, useEffect } from 'react';
import AboutMe from '../AboutMe/AboutMe';
import Skills from '../Skills/Skills';
import styled from 'styled-components';
import WorkExperience from '../Experience/WorkExperience';
import Portfolio from '../Portfolio/Portfolio';
import Loader from '../Loader/Loader';
import { getData } from '../../services/api-data';
import { useLocation } from 'react-router-dom';

const HomeStyled = styled.div`

`

const Home = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const location = useLocation();

    // Simulate component load with a timeout
    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 500);
    }, []);

    useEffect(() => {
        // On component load, check if URL has a hash
        const hash = window.location.hash;

        const validateHash = () => {
            if (hash) {
                // console.log(`URL has a hash: ${hash}`);
                const id = hash.substring(1);
                const element = document.getElementById(id);

                if (element) {
                        element.scrollIntoView({ behavior: "smooth", block: "start" });
                } else {
                    // console.log(`Element with id '${id}' not found.`);
                }
            } else {
                // console.log('URL does not have a hash.');
            }
        };

        validateHash();


    }, [location, isLoaded]);



    const [skills, setSkills] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData('technologies-data').then(({ data, isError }) => {

            if (isError) {
                console.log('No Skills Found');
                setLoading(true);
                return
            }
            setLoading(false);
            setSkills(data);
            // console.log('Skills Data');
            // console.log(data);
        })
    }, []);

    const [work, setWork] = useState([]);

    useEffect(() => {
        getData('work-history-data').then(({ data, isError }) => {

            if (isError) {
                console.log('No Work History Found');
                setLoading(true);
                return
            }

            setLoading(false);
            setWork(data);

        })
    }, []);

    const [project, setProject] = useState([]);

    useEffect(() => {
        getData('all-projects-api-data').then(({ data, isError }) => {
            if (isError) {
                console.log('No projects found...');
                setLoading(true);
                return;
            }

            setLoading(false);
            setProject(data);

        })
    }, []);


    if (loading) {
        return <Loader />
    }

    return (
        <HomeStyled>
            <Intro />
            <AboutMe />
            <Skills skillsList={skills} />
            <WorkExperience workList={work} />
            <Portfolio projectList={project} />
        </HomeStyled>
    )
}

export default Home;
