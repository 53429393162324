import styled from 'styled-components'

const FooterStyled = styled.div`
    min-height: 300px;
    /* background-color: var(--dark-gray); */
    padding: 1em;
    text-align: center;
    margin-top: 2em;
    padding-top: 2em;
    /* border-top: 1px solid var(--dark-gray); */
`

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <FooterStyled className='footer-wrapper'>
            <div className="background">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

            
            <div className="footer-content">
                <h3>
                    DLC
                </h3>

                <p className="copy-right">
                    ⓒ Copyright {currentYear} <br />
                    Developed by Diego A Londono
                </p>
            </div>    

        </FooterStyled>
    )
}

export default Footer
