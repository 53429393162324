import styled from 'styled-components'
import notFoundImage from '../../assets/404-error.png'
import { Component } from 'react'

const NotFoundStyled = styled.div`
    min-height: 500px;
    max-width: 1200px;
    padding-top: 7em;
    margin: 0 auto 3em;

    .image-container {
        max-width: 300px;
        margin: 4em auto 2em;
        padding: 0 1em;

        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    h3 {
        font-family: var(--raleway);
        color: var(--white);
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        padding: 0 1em;
    }
`

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.timerId = null; // To keep track of the timer
    }

    componentDidMount() {
        this.timerId = setTimeout(() => {
            // this.setState({ showMessage: true });
            // console.log('componentDidMount setTimeout 1000')
        }, 500); // 3 seconds
    }

    componentWillUnmount() {
        // Cleanup timer
        clearTimeout(this.timerId);
    }

    render() {
        return (
            <NotFoundStyled className='page-not-found'>
                <div className="image-container">
                    <img src={notFoundImage} alt="Page Not Found" />
                </div>
                <h3>
                    Can't seem to find the page you're looking for. <br />
                    It might have been moved, deleted, or perhaps it never existed.
                </h3>
            </NotFoundStyled>
        )
    }
}

export default NotFound
