import { createGlobalStyle } from 'styled-components';

const GlobalStylesStyled = createGlobalStyle`

    :root {
        /* Primary Colors */
        --white: #ffffff;
        --black: #000000;

        /* Site Colors */
        --green: #85f000;
        --dark-green: #2a4b01;
        --light-gray: #b7b7b7;
        --dark-gray: #3d3b3b;
        --super-light-gray: #EEEFED;
        

        /* Fonts */
        --raleway: 'Raleway', sans-serif;
        --qwigley: 'Qwigley', sans-serif;
        --blackopsone : "Black Ops One", sans-serif;
        
    }

    body {
        /* border-bottom: 10px solid var(--super-light-gray); */
        background-color: var(--black);
        /* padding-top: 6em; */
    }
`

export default GlobalStylesStyled
