import styled from 'styled-components'
import props from '../../data/about-me-data';
import aboutMeBgSecond from '../../assets/about-me-bg-v2.jpeg';

const AboutMeStyled = styled.div`

    background-image: url(${aboutMeBgSecond});
    background-size: cover;
    background-position: top right;
    position: relative;
    min-height: 500px;
    max-width: 1200px;
    margin: 1em auto 10em;
    font-family: var(--raleway);
    border-left: 1px solid var(--dark-gray);

    #about-me {
        position: absolute;
        top: -11em;
        width: 100%;
        left: 0;
        height: 50px;
        background: var(--dark-gray);
        z-index: -1;
    }

    &:after {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-image: linear-gradient(to bottom,transparent 50%,#000 111%),linear-gradient(to left,transparent 10%,rgb(0 0 0) 68%);
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: none;
        left: 0;
        top: 0;
        z-index: 1;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        display: none;
    }

`

const AboutMe = () => {

    const { about_me, who_i_am, about_me_copy } = props;

    return (
        <AboutMeStyled >
            <div id='about-me'></div>
            <div className="about-me-section-wrapper">
                <div className="about-me heading-section">
                    <div className="section-text small-text">
                        <div className="small-text-rotated">
                            {who_i_am}
                        </div>
                    </div>

                    <span className="section-text large-text" >
                        {about_me}
                    </span>
                </div>

                <article className="about-me copy" data-aos="zoom-out-up">
                    <p>
                        {about_me_copy}
                    </p>
                </article>

            </div>

        </AboutMeStyled>
    )
}

AboutMe.displayName = "AboutMe";

export default AboutMe;
