import styled from 'styled-components'
import WorkItem from './WorkItem';
import props from '../../data/work-experience-data';

const WorkExperienceStyled = styled.div`
    position: relative;
    min-height: 400px;
    max-width: 1200px;
    margin: 5em auto 6em;
    padding: 0 1em 11em;
    font-family: var(--raleway);
    border-bottom: 1px dashed var(--dark-gray);

    #work-experience {
        position: absolute;
        top: -8em;
        width: 100%;
        left: 0;
        height: 50px;
        z-index: -1;
    }

    .work-history-items-container {
        max-width: 800px;
        margin: 1em auto 0;
        position: relative;

        &:before {
            content: "";
            width: 2px;
            height: 109%;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -4em;
            background: var(--dark-gray);
        }
    }
    

    .work-item-wrapper {
        &:nth-child(odd) {
            .work-item-content {
                float: left;
                border-right: 1px solid var(--green);

                @media only screen and (max-width: 850px) {
                    float: none;
                    margin: 4em auto 1em;
                    border-right: none;
                    padding-top: 1.2em;
                    text-align: left;
                }

                &:before {
                    right: -60px;

                    @media only screen and (max-width: 850px) {
                        left: 0;
                        right: 0;
                        top: -4em;
                        margin: 0 auto;
                        width: 10px;
                        height: 10px;
                    }
                }

                .triangle {
                    top: 45%;
                    right: -26px;

                    @media only screen and (max-width: 850px) {
                        top: -1.1em;
                        right: 0;
                        left: 0;
                        margin: 0 auto;
                        transform: rotate(0deg);
                    }
                }
            }
        }

        &:nth-child(even) {
            .work-item-content {
                float: right;
                border-left: 1px solid var(--green);

                @media only screen and (max-width: 850px) {
                    float: none;
                    margin: 4em auto 1em;
                    border-left: none;
                    padding-top: 1.2em;
                    text-align: right;
                }

                &:before {
                    left: -60px;

                    @media only screen and (max-width: 850px) {
                        left: 0;
                        right: 0;
                        top: -4em;
                        margin: 0 auto;
                        width: 10px;
                        height: 10px;
                    }
                }

                .triangle {
                    top: 45%;
                    left: -26px;
                    transform: rotate(270deg);

                    @media only screen and (max-width: 850px) {
                        top: -1.1em;
                        right: 0;
                        left: 0;
                        margin: 0 auto;
                        transform: rotate(0deg);
                    }
                }
            }
        }
        
    }
    

`

const WorkExperience = ({ workList }) => {

    const { work_exp_title, work_exp_short_title } = props;

    return (
        <WorkExperienceStyled className="work-history-main-wrapper">

            <div id='work-experience'></div>

            <div className="work-history-header-container">

                <div className="work-history-section-title">
                    {work_exp_title}
                </div>

                <div className="work-history-section-small-title" data-aos="zoom-in">
                    {work_exp_short_title}
                </div>

            </div>

            <div className="work-history-items-container">
                {workList.map((item) => {
                    return <WorkItem {...item} key={item.nid} />
                })}
            </div>
        </WorkExperienceStyled>
    )
}

export default WorkExperience;
