import styled from 'styled-components';
import PortfolioItem from './PortfolioItem';
import Slider from "react-slick";

const PortfolioStyled = styled.div`

`

function Portfolio({ projectList }) {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 3,
                },
            },
        ],
        rows: 2,
        slidesPerRow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
    };

    return (
        <PortfolioStyled className="portfolio-main-wrapper">           
            <div id='portfolio'></div>

            <div className="portfolio-large-title" >
                Portfolio
            </div>

            <div className="portfolio-small-title">
                Projects
            </div>

            <Slider {...settings} className="slider-container">
                {projectList.map((item) => {
                    return <PortfolioItem {...item} key={item.nid}/>
                })}
            </Slider>
        </PortfolioStyled>
    )
}

export default Portfolio
