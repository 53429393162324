import styled from "styled-components";
import MyPhoto from "../../assets/me-2.jpeg";
import props from "../../data/intro-data";

const InstroStyled = styled.div`
    position: relative;

    #intro {
        position: absolute;
        top: -11em;
        width: 100%;
        left: 0;
        height: 50px;
        background: var(--dark-gray);
        z-index: -1;
    }
`;

const Intro = () => {
  return (
    <InstroStyled className="intro-main-wrapper">
      <div id="intro"></div>
      <div className="intro-content">
        <div className="intro-content--left-section">
          <div className="intro-content--left-section-content">
            <h4>{props.welcome}</h4>
            <h3>{props.i_am}</h3>
            <h2>{props.my_name}</h2>
            <h5>{props.my_role}</h5>

            <p>{props.my_short_intro}</p>
          </div>
        </div>
        <div className="intro-content--right-section">
          <div className="intro-content--right-section-content">
            <img src={MyPhoto} alt="Diego Londono" />
          </div>
        </div>
      </div>

      {/* <div className="intro-waves"></div> */}

      <div className="waves-container">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(64,190,10,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(40,152,7,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(13,115,3,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#40be0a" />
          </g>
        </svg>
      </div>
    </InstroStyled>
  );
}

export default Intro;
