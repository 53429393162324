import styled from 'styled-components'

const SkillItemsStyled = styled.div`

    min-height: 100px;
    position: relative;
    flex: 1 0 200px;
    margin-bottom: 1em;

    @media only screen and (max-width: 700px) {
        flex: 1 0 90px;
    }

    .skill-item-logo {
        img {
            display: block;
            margin: 1em auto;
            width: 60px;
            height: 60px;
        }
    }

    .skill-item-name {
        text-align: center;
        color: var(--white);
    }
`

const SkillItems = (props) => {

    const IMG_BASE_URL = 'https://drupal-api.in-deev.com';

    return (
        <SkillItemsStyled key={props.id} className="skill-item">

            <div className="skill-item-logo">
                <img src={`${IMG_BASE_URL}${props.field_tech_logo}`} alt={props.name} />
            </div>

            <div className="skill-item-name">
                {props.name}
            </div>

        </SkillItemsStyled>
    )
}

export default SkillItems;
