import styled from 'styled-components';
import props from '../../data/skills-data';
import SkillItems from './skill-items';

const SkillsStyled = styled.div`
    position: relative;
    min-height: 400px;
    max-width: 1200px;
    margin: 3em auto;
    padding-bottom: 2em;
    font-family: var(--raleway);
    border-bottom: 1px dashed var(--dark-gray);

    #skills-and-tools {
        position: absolute;
        top: -8em;
        width: 100%;
        left: 0;
        height: 50px;
        z-index: -1;
    }

    .dynamic-anchor {
        position: absolute;
        width: 100%;
        height: 20px;
        background-color: var(--dark-gray);
        top: 2em;
        left: 0;
    }

    .skills-list-wrapper {
        margin-bottom: 3em;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 1em;
    }

`

const Skills = ({ skillsList }) => {

    const { skills_title, what_i_do, skills_copy } = props;

    return (
        <SkillsStyled>
            <div id='skills-and-tools'></div>
            <div className="skills-section-main-wrapper">

                <div className="skills large-text" >
                    {skills_title}
                </div>
                
                <div className="skills small-text" data-aos="zoom-in">
                    {what_i_do}
                </div>

                <div className="skills copy">
                    {skills_copy}
                </div>

                <div className="skills-list-wrapper">
                    {skillsList.map((item) => {
                        return <SkillItems {...item} key={item.tid} />
                    })}
                </div>
                
            </div>
        </SkillsStyled>
    )
}

export default Skills;
