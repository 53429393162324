import styled from 'styled-components'
import '../global-styles/globals/globals.scss';

const LayoutStyled = styled.div`
    background: #000;
    min-height: 100vh;
`

function Layout({ children }) {
    return (
        <LayoutStyled className='layout'>
            { children }
        </LayoutStyled>
    )
}

export default Layout
