import styled from 'styled-components'

const LoaderStyled = styled.div`
    height: 100%;
    position: absolute;
    width: 100%;
    background: var(--black);
    left: 0;
    top: 0;
    z-index: 6;

    .loader-container {
        width: 250px;
        margin: 0 auto;
        position: relative;
        height: 250px;
        top: 40%;
        border: 1px solid var(--dark-grey);

        .loader-title {
            font-family: var(--blackopsone);
            color: var(--green);
            font-size: 50px;
            text-align: center;
        }

        /* HTML: <div class="loader"></div> */
        .loader {
            width: 55px;
            margin: 1em auto;
            aspect-ratio: 1;
            --g1:conic-gradient(from  90deg at 3px  3px ,#0000 90deg,#fff 0);
            --g2:conic-gradient(from -90deg at 22px 22px,#0000 90deg,#fff 0);
            background:var(--g1),var(--g1),var(--g1), var(--g2),var(--g2),var(--g2);
            background-size: 25px 25px;
            background-repeat: no-repeat;
            animation: l7 1.5s infinite;
        }
        @keyframes l7 {
            0%   {background-position:0    0   ,0 100%,100% 100% }
            25%  {background-position:100% 0   ,0 100%,100% 100% }
            50%  {background-position:100% 0   ,0 0   ,100% 100% }
            75%  {background-position:100% 0   ,0 0   ,0    100% }
            100% {background-position:100% 100%,0 0   ,0    100% }
        }
    }

`

function Loader() {
    return (
        <LoaderStyled>
            <div className="loader-container">
                <div className="loader-title">
                    DLC
                </div>
                <div className="loader">

                </div>
            </div>
        </LoaderStyled>
    )
}

export default Loader
