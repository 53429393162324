import styled from 'styled-components'
import { useEffect, useState } from 'react';
import { getData } from '../../services/api-data';
import { useParams, useLocation } from 'react-router-dom';
import Loader from '../Loader/Loader';

const ProjectPageStyled = styled.div`

`


const ProjectPage = ({ projectPageItem }) => {

    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function handdleNavClick(e) {
        const element = document.getElementById(e.currentTarget.dataset.id);
        const dataset = e.currentTarget.dataset.id;
        console.log('Item clicked: ' + dataset);
        element.scrollIntoView({behavior: "smooth", block: "start"});
    }

    const params = useParams();
    const projectName = params.project;

    const [singleProject, setSingleProject] = useState([]);

    useEffect(() => {
        getData(`single-project-data/${projectName}`).then(({ data, isError }) => {
            if (isError) {
                console.log('No project found...');
                setLoading(true);    
            } else {                
                setSingleProject(data);
                setLoading(false);
            }
            
            
        });
    }, [projectName]);

    const IMG_BASE_URL = 'https://drupal-api.in-deev.com';

    if (loading) {
        return <Loader />
    }

    return (
        <ProjectPageStyled className='project-main-wrapper'>

            {singleProject.map((item, index) => (

                <div className="project-main-wrapper" key={index}>
                    <div className="project-banner-section" style={{ backgroundImage: `url('${IMG_BASE_URL}${item.field_banner_image}')` }}>
                        <div className="container">
                            <div className="project-banner-title">
                                <h2>{item.title}</h2>
                            </div>
                        </div>
                    </div>

                    <div className="container">

                        <div className="project-details--wrapper desktop">
                            <div className="project-details-content left">
                                <div className="project-details-view-container">
                                    <img src={`${IMG_BASE_URL}${item.field_desktop_view}`} alt={item.title} />
                                </div>
                            </div>

                            <div className="project-details-content right">
                                <div className="outer">
                                    <div className="inner">
                                        <div className="project-details--title">
                                            Company Type
                                        </div>
                                        <div className="project-details--item" data-aos="zoom-out-up" data-aos-duration="2000">
                                            {item.field_company_type}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="project-details--wrapper tablet">
                            <div className="project-details-content left">
                                <div className="outer">
                                    <div className="inner">
                                        <div className="project-details--title">
                                            Technologies Used
                                        </div>
                                        <div className="project-details--item">
                                            <div className='tech-used' dangerouslySetInnerHTML={{ __html: item.field_tech_used }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="project-details-content right">
                                <div className="project-details-view-container">
                                {item.field_tablet_view ? 
                                    <img src={`${IMG_BASE_URL}${item.field_tablet_view}`} alt={item.title} data-aos="zoom-out-down" data-aos-duration="2000"/>
                                : 
                                    <img src={`${IMG_BASE_URL}${item.field_mobile_view}`} className='mobile-image' alt={item.title} data-aos="zoom-out-up" data-aos-duration="2000"/>
                                }
                                
                                </div>
                            </div>
                        </div>

                        <div className="project-details--wrapper mobile">
                            <div className="project-details-content left">
                                <div className="project-details-view-container">
                                {item.field_tablet_view ? 
                                    <img src={`${IMG_BASE_URL}${item.field_mobile_view}`} alt={item.title} data-aos="zoom-out-down" data-aos-duration="2000"/>
                                : 
                                    null
                                }
                                    <a href={item.field_project_link} className="visit-site" target='blank' data-aos="zoom-out-down" data-aos-duration="3000">
                                    Visit Project
                                </a>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            ))}
        </ProjectPageStyled>
    )
}

export default ProjectPage;
