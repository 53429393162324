import styled from 'styled-components';
import Navbar from '../Navbar/Navbar';

const HeaderStyled = styled.div`

`


const Header = () => {
    return (
        <HeaderStyled className="Header">
            <div className="header-content">
                <Navbar />
            </div>
        </HeaderStyled>
    )
}

export default Header;
